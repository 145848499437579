import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import { saveAs } from 'file-saver'
import { useAlert } from 'react-alert';
import { getReportCsv } from '../../lib/fetch/report';
import { TokenContext } from '../../components/Auth';
import { useLoading } from '../Loading';
import { useTranslation } from 'react-i18next';

const ReportButton = ({ id, name = '' }) => {

  const [t] = useTranslation();
  const alert = useAlert()
  const [token] = useContext(TokenContext);
  const { showLoading, hideLoading } = useLoading();

  const download = async (event) => {
    event.stopPropagation()

    try {
      showLoading()
      const { data } = await getReportCsv(token, id)
      const blob = new Blob([data], { type: 'text/csv' });

      const fileName = `Corypha_Report_${name}_${new Date().getTime()}.csv`
      saveAs(blob, fileName);

      hideLoading()
    } catch (err) {
      console.log(err)
      hideLoading()
      alert.error(t('COMMON:GENERIC_ERROR'))
    }
  }

  return (
    <Button onClick={download} className="btn-back">
      REPORT
    </Button>
  )
}

export default ReportButton;