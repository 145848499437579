import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Popover, CardBody, PopoverHeader, Progress, Table, Button } from 'reactstrap';
import Menu from '../../components/Menu';
import './Dashboard.scss';
import BurgerMenu from '../../components/BurgerMenu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { ProfileContext } from '../../components/Auth';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import copyIcon from '../../images/icn-copy.svg';
import AuditsDashboard from '../../components/AuditsDashboard';

function Dashboard() {
  const [t] = useTranslation('DASHBOARD');
  const [profile] = useContext(ProfileContext);
  const [copied, setCopied] = useState(false);

  const { monthUsage = 0, monthFree = 0 } = profile
  const percentage = monthUsage * 100 / monthFree
  const billable = (monthUsage - monthFree) > 0 ? (monthUsage - monthFree) : 0
  const cost = (billable * Number(process.env.REACT_APP_COST_SINGLE_CALL)).toFixed(2)

  return (
    <div className="App">
      <Menu />
      <BurgerMenu />

      <div className="corpo dashboard">

        <section>
          <Header title={t('TITLE')} />

          <h4>{t('API_KEY')}</h4>

          <Card>
            <CardBody>
              <div className="row">
                <div className="col-9 col-sm-10">
                  <h6 id="api-key">{profile.apiKey}</h6>
                </div>
                <div className="col-3 col-sm-2 text-right">
                  <CopyToClipboard text={profile.apiKey}>
                    <Button id="icon-copy" className="btn-radius"><img src={copyIcon} alt="copy" /></Button>
                  </CopyToClipboard>
                  <Popover
                    placement="bottom"
                    isOpen={copied}
                    trigger="legacy"
                    target="icon-copy"
                    toggle={() => setCopied(!copied)}
                  >
                    <PopoverHeader>Copied!</PopoverHeader>
                  </Popover>
                </div>
              </div>
            </CardBody>
          </Card>

          <h4>{t('API_KEY_USAGE')}</h4>
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <Progress value={percentage} className={percentage > 80 ? 'danger' : ''} />
                  <p className="text-center">
                    <strong>{monthUsage}</strong> {t('CALLS_USAGE_1')} <strong>{monthFree}</strong> {t('CALLS_USAGE_2')}
                  </p>
                  <AuditsDashboard />
                </div>
                <div className="col-sm-12 col-md-6">
                  <Table responsive className="data-table no-hover">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{t('CURRENT_MONTH')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><strong>{t('TOTAL_CALLS')}</strong></td>
                        <td>{monthUsage}</td>
                      </tr>
                      <tr>
                        <td><strong>{t('BILLABLE_CALLS')}</strong></td>
                        <td>{billable}</td>
                      </tr>
                      <tr>
                        <td><strong>{t('COSTS')}</strong></td>
                        <td>{cost}€</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </CardBody>
          </Card>

        </section>
        <Footer />
      </div>

    </div>
  );
}

export default Dashboard;
