import React from 'react';
import LangBadge from '../../components/LangBadge';

function PublishedVersions({ versions = [] }) {
  if (!versions || versions.length === 0) {
    return null
  }
  return (
    <>
      {versions.filter(v => v.published && !v.outdated).map(v => {
        return (
          <p style={{ margin: 0 }}>{v.number} <LangBadge lang={v.lang} /></p>
        )
      })}
    </>
  );
}

export default PublishedVersions;