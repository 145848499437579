import axios from 'axios';

export const savePreference = async ({ token, id, versionId, preference }) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/policies/${id}/versions/${versionId}/preferences`,
      headers: {
        Authorization: token
      },
      data: preference
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const deletePreference = async ({ token, id, versionId, preferenceId }) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/policies/${id}/versions/${versionId}/preferences/${preferenceId}`,
      headers: {
        Authorization: token
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};