import axios from 'axios';

export const saveVersion = async ({ token, id, versionId, version }) => {
  try {
    const response = await axios({
      method: versionId ? 'put' : 'post',
      url: `${process.env.REACT_APP_API_URL}/policies/${id}/versions` + (versionId ? `/${versionId}` : ''),
      headers: {
        Authorization: token
      },
      data: version
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const getVersion = async ({ token, id, versionId }) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/policies/${id}/versions/${versionId}`,
      headers: {
        Authorization: token
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const deleteVersion = async ({ token, id, versionId }) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/policies/${id}/versions/${versionId}`,
      headers: {
        Authorization: token
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const publishVersion = async ({ token, id, versionId }) => {
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/policies/${id}/versions/${versionId}/published`,
      headers: {
        Authorization: token
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};