import React, { useContext, useEffect, useState } from 'react';
import PolicyPresentational from './PolicyPresentational';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { TokenContext } from '../../components/Auth';
import { getPolicy, savePolicy, deletePolicy } from '../../lib/fetch/policies';
import { useLoading } from '../../components/Loading';
import { deleteVersion } from '../../lib/fetch/versions';

function Policy(props) {
  const [token] = useContext(TokenContext);
  const { t } = useTranslation();
  const alert = useAlert();
  const [policy, setPolicy] = useState();
  const { id } = props.match.params
  const { showLoading, hideLoading } = useLoading();

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    if (!token || !id) return

    showLoading()

    const { error, data = {} } = await getPolicy({ token, id });

    hideLoading()

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'))

      const { status } = error.response || {}
      if (status === 404 || status === 400) {
        props.history.push('/ops')
      }
      return
    }

    setPolicy(data)
  }

  const onSavePolicy = async ({ code, name }) => {
    showLoading()

    const { error, data = {} } = await savePolicy({ token, id, name, code });

    hideLoading()

    if (error) {
      const { status } = error.response || {}
      alert.error(t(status === 409 ? 'POLICIES:ERR_DUP_CODE' : 'COMMON:GENERIC_ERROR'))
      return;
    }

    alert.success(t('COMMON:SAVE_SUCCESS'))

    if (!id) {
      props.history.push(`/policy/${data.id}`)
    }

    fetchData()
  }

  const onDeletePolicy = async () => {
    if (!id) { return }

    showLoading()
    const { error } = await deletePolicy({ token, id });
    hideLoading()

    if (error) {
      const { status } = error.response || {}
      alert.error(t(status === 403 ? 'POLICIES:ERR_DELETE_PERMISSION' : 'COMMON:GENERIC_ERROR'))
      return;
    }

    alert.success(t('COMMON:SAVE_SUCCESS'))

    props.history.push(`/policies`)
  }

  const onDeleteVersion = async (versionId) => {
    showLoading()
    const { error } = await deleteVersion({ token, id, versionId });
    hideLoading()

    if (error) {
      const { status } = error.response || {}
      alert.error(t(status === 403 ? 'VERSIONS:ERR_DELETE_PERMISSION' : 'COMMON:GENERIC_ERROR'))
      return;
    }

    alert.success(t('COMMON:DELETE_SUCCESS'))

    fetchData()
  }

  return (
    <PolicyPresentational
      onSavePolicy={onSavePolicy}
      onDeletePolicy={onDeletePolicy}
      onDeleteVersion={onDeleteVersion}
      policy={policy}
      {...props}
    />
  );
}

export default Policy;
