import React, { useState, useEffect } from 'react';
import Proptypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import Menu from '../../components/Menu';
import BurgerMenu from '../../components/BurgerMenu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Card, Button, CardBody, Form, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import ChipInput from 'material-ui-chip-input'
import { NATION_CODES } from '../../lib/nations/nations';
import FormErrorMessage from '../../components/FormErrorMessage';
import './Profile.scss';

function ProfilePresentational({ onUpdate, profile }) {
  const [hosts, setHosts] = useState([]);
  const { t } = useTranslation('PROFILES');
  const { handleSubmit, register, errors, watch, getValues, clearError, setValue } = useForm();

  const watchNation = watch('nation');

  const { id, email, monthLimit, hosts: profileHosts = '', billing, name } = profile || {};
  const { address, recipientCode, pec, vat } = billing || {};
  const { city, nation, rue, zip } = address || {};

  useEffect(() => {
    setHosts((profileHosts && profileHosts.length > 0) ? profileHosts.split(',') : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return (
    <div className="App">
      <Menu />
      <BurgerMenu />

      <div className="corpo dashboard">
        <section>
          <Header title={t('PROFILE')} />

          <h4>{t('INFO_PROFILE')}</h4>

          <Form onSubmit={handleSubmit((values) => {
            values.hosts = hosts.join(',');
            onUpdate(values);
          })}>
            <Card>
              <CardBody>
                <div className="row">
                  <div className="col-6">
                    <FormGroup>
                      <Label>{t('EMAIL')}</Label>
                      <Input
                        disabled={true}
                        defaultValue={email}
                        type="text"
                        name="email"
                        innerRef={register}
                      />
                      <FormErrorMessage errors={errors} name="email" />
                    </FormGroup>
                  </div>
                  <div className="col-6">
                    {/* TODO: disabilitare il campo se l'utente è nel piano free */}
                    <FormGroup>
                      <Label>{t('MONTH_LIMIT')}</Label>
                      <Input
                        defaultValue={monthLimit}
                        type="number"
                        name="monthLimit"
                        innerRef={register}
                      />
                      <FormErrorMessage errors={errors} name="monthLimit" />
                    </FormGroup>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <FormGroup>
                      <Label>{t('HOSTS')}</Label>
                      <ChipInput
                        value={hosts}
                        onAdd={(chip) => setHosts(hosts.concat(chip))}
                        onDelete={(chip) => setHosts(hosts.filter(h => h !== chip))}
                        fullWidthInput={true}
                        fullWidth={true}
                        placeholder={t('HOSTS_INSTRUCTIONS')}
                        classes={{
                          input: 'chips-input',
                          chipContainer: 'chips-container'
                        }}
                      />
                      <FormErrorMessage errors={errors} name="hosts" />
                    </FormGroup>
                  </div>
                </div>


              </CardBody>
            </Card>

            <h4>{t('INFO_BILLING')}</h4>

            <Card>
              <CardBody>
                <div className="row">
                  <div className="col-6">
                    <FormGroup>
                      <Label>{t('NAME')}</Label>
                      <Input
                        defaultValue={name}
                        type="text"
                        name="name"
                        innerRef={register}
                      />
                      <FormErrorMessage errors={errors} name="name" />
                    </FormGroup>
                  </div>
                  <div className="col-6">
                    <FormGroup>
                      <Label>{t('VAT')}</Label>
                      <Input
                        defaultValue={vat}
                        type="text"
                        name="vat"
                        innerRef={register}
                      />
                      <FormErrorMessage errors={errors} name="vat" />
                    </FormGroup>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <FormGroup>
                      <Label>{t('RUE')}</Label>
                      <Input
                        defaultValue={rue}
                        type="text"
                        name="rue"
                        innerRef={register}
                      />
                      <FormErrorMessage errors={errors} name="rue" />
                    </FormGroup>
                  </div>
                  <div className="col-6">
                    <FormGroup>
                      <Label>{t('ZIP')}</Label>
                      <Input
                        defaultValue={zip}
                        type="text"
                        name="zip"
                        innerRef={register}
                      />
                      <FormErrorMessage errors={errors} name="zip" />
                    </FormGroup>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <FormGroup>
                      <Label>{t('CITY')}</Label>
                      <Input
                        defaultValue={city}
                        type="text"
                        name="city"
                        innerRef={register}
                      />
                      <FormErrorMessage errors={errors} name="city" />
                    </FormGroup>
                  </div>
                  <div className="col-6">
                    <FormGroup>
                      <Label>{t('NATION')} *</Label>
                      {
                        id &&
                        <CustomInput
                          id="nation"
                          defaultValue={nation}
                          type="select"
                          name="nation"
                          innerRef={register({ required: t('COMMON:REQUIRED') })}
                          onChange={e => {
                            clearError(['pec', 'recipientCode'])
                            if (e.target.value !== 'IT') {
                              setValue('pec', '')
                              setValue('recipientCode', '')
                            }
                          }}
                        >
                          {
                            NATION_CODES.map(code => ({ value: code, label: t(`NATIONS:${code}`) }))
                              .sort((a, b) => {
                                if (a.label < b.label) return -1
                                if (a.label > b.label) return 1
                                return 0;
                              })
                              .map(({ value, label }) => (
                                <option key={value} value={value}>{label}</option>
                              ))
                          }
                        </CustomInput>
                      }
                      <FormErrorMessage errors={errors} name="nation" />
                    </FormGroup>
                  </div>
                </div>
                <div className={`row ${watchNation === 'IT' ? '' : 'hidden'}`}>
                  <div className="col-6">
                    <FormGroup>
                      <Label>{t('PEC')} *</Label>
                      <Input
                        defaultValue={pec}
                        type="email"
                        name="pec"
                        innerRef={register({
                          validate: (pec) => {
                            const { recipientCode, nation } = getValues()
                            if (pec && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(pec)) {
                              return t('COMMON:INVALID_EMAIL')
                            }
                            if (nation === 'IT' && !recipientCode && !pec) {
                              return t('AT_LEAST_RECIPIENT_CODE_PEC')
                            }
                            return true
                          }
                        })}
                      />
                      <FormErrorMessage errors={errors} name="pec" />
                    </FormGroup>
                  </div>
                  <div className="col-6">
                    <FormGroup>
                      <Label>{t('RECIPIENT_CODE')} *</Label>
                      <Input
                        defaultValue={recipientCode}
                        type="text"
                        name="recipientCode"
                        innerRef={register({
                          validate: (recipientCode) => {
                            const { pec } = getValues()
                            if (recipientCode && recipientCode.length !== 7) {
                              return t('RECIPIENT_CODE_LENGTH')
                            }
                            if (nation === 'IT' && !recipientCode && !pec) {
                              return t('AT_LEAST_RECIPIENT_CODE_PEC')
                            }
                            return true
                          }
                        })}
                      />
                      <FormErrorMessage errors={errors} name="recipientCode" />
                    </FormGroup>
                  </div>
                </div>
              </CardBody>
            </Card>

            <div className="text-right m-b-35">
              <Button type="submit" className="btn" style={{ marginTop: '0' }}>
                {t('COMMON:SAVE')}
              </Button>
            </div>

          </Form>
        </section>
        <Footer />
      </div>

    </div>
  );
}

ProfilePresentational.propTypes = {
  onUpdate: Proptypes.func.isRequired,
  profile: Proptypes.object,
};

export default ProfilePresentational;
