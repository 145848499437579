import React from 'react';
import Menu from '../../components/Menu';
import BurgerMenu from '../../components/BurgerMenu';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import { Card, CardHeader, CardBody, Table } from 'reactstrap';
import PaginationCustom from '../../components/PaginationCustom';
import InfoCont from '../../components/InfoCont';
import Footer from '../../components/Footer';
import moment from 'moment';

function AppPolicy({ audits = [], total = 0, loading }) {
  const { t } = useTranslation('AUDITS');

  return (
    <div className="App">
      <Menu />
      <BurgerMenu />

      <div className="corpo">

        <section>
          <Header title={t('AUDITS')} />

          <Card>
            <CardHeader>
              <h4>{t('HISTORICAL_AUDITS')}</h4>
            </CardHeader>
            <CardBody className="no-padding">
              {
                !loading && audits && audits.length === 0 && <InfoCont message={t('EMPTY_LIST')} />
              }
              {
                audits && audits.length > 0 &&
                <Table responsive hover className="data-table">
                  <thead>
                    <tr>
                      <th>{t('METHOD')}</th>
                      <th>{t('URL')}</th>
                      <th>{t('HOST')}</th>
                      <th>{t('QUERY_STRING')}</th>
                      <th>{t('DATE')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      audits.map((audit, key) => {
                        const { url, host, method, querystring, createdAt, } = audit
                        return (
                          <tr key={key}>
                            <td>{method}</td>
                            <td>{url}</td>
                            <td>{host}</td>
                            <td>{querystring}</td>
                            <td>{moment(createdAt).format('DD-MM-YYYY HH:mm')}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>
              }

            </CardBody>
          </Card>
          {
            audits && audits.length > 0 &&
            <PaginationCustom total={total} />
          }
        </section>
      </div>
      <Footer />
    </div>

  );
}

export default AppPolicy;
