import axios from 'axios';
const URL = process.env.REACT_APP_POLICY_APP_URL
const CODE = process.env.REACT_APP_POLICY_CODE
const API_KEY = process.env.REACT_APP_POLICY_API_KEY

export const checkPolicy = async (userId, lang = 'it') => {
  try {
    const response = await axios({
      method: 'get',
      url: `${URL}/v1/policies/${CODE}/users/${userId}/check?lang=${lang}`,
      headers: {
        'X-API-KEY': API_KEY
      }
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const acceptPolicy = async (userId, preferences = []) => {
  try {
    const response = await axios({
      method: 'put',
      url: `${URL}/v1/policies/${CODE}/users/${userId}`,
      headers: {
        'X-API-KEY': API_KEY
      },
      data: { preferences }
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const fetchMyConsents = async (userId) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${URL}/v1/policies/${CODE}/users/${userId}`,
      headers: {
        'X-API-KEY': API_KEY
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const getPolicy = async (lang = 'it') => {
  try {
    const response = await axios({
      method: 'get',
      url: `${URL}/v1/policies/${CODE}?lang=${lang}`,
      headers: {
        'X-API-KEY': API_KEY
      }
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};