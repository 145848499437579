import React from 'react';
import Proptypes from 'prop-types';
import { Card, CardHeader, CardBody, Table, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import './Policies.scss';
import Menu from '../../components/Menu';
import BurgerMenu from '../../components/BurgerMenu';
import Header from '../../components/Header';
import plusIcon from '../../images/icn-plus.svg';
import Footer from '../../components/Footer';
import PaginationCustom from '../../components/PaginationCustom';
import { Link } from 'react-router-dom';
import InfoCont from '../../components/InfoCont';
import ReportButton from '../../components/ReportButton';
import PublishedVersions from './PublishedVersions';

function PoliciesPresentational({ policies, total, history, loading }) {
  const { t } = useTranslation('POLICIES');

  return (
    <div className="App">
      <Menu />
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header title={t('TITLE')} />

          <Card>
            <CardHeader>
              <div className="row">
                <div className="col-6 text-left">
                  <h4>
                    {t('LIST_POLICY')}
                  </h4>
                </div>
                <div className="col-6 text-right">
                  <Link to="/policy">
                    <Button className="btn-radius"><img src={plusIcon} alt="plus" /></Button>
                  </Link>
                </div>
              </div>
            </CardHeader>
            <CardBody className="no-padding">
              {
                !loading && policies && policies.length === 0 && <InfoCont message={t('EMPTY_LIST')} />
              }
              {
                policies && policies.length > 0 &&
                <Table responsive hover className="data-table">
                  <thead>
                    <tr>
                      <th>{t('NAME')}</th>
                      <th>{t('CODE')}</th>
                      <th>{t('PUBLISHED_VERSION')}</th>
                      <th>{t('REPORT_CONSENTS')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      policies.map(({ id, name, code, versions }) => (
                        <tr key={id} onClick={() => history.push(`/policy/${id}`)}>
                          <td>{name}</td>
                          <td>{code}</td>
                          <td><PublishedVersions versions={versions} /></td>
                          <td>
                            <ReportButton id={id} name={name} />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              }

            </CardBody>
          </Card>
          {
            policies && policies.length > 0 &&
            <PaginationCustom total={total} />
          }
        </section>
        <Footer />
      </div>
    </div>
  );
}

PoliciesPresentational.propTypes = {
  policies: Proptypes.array,
};

export default PoliciesPresentational;
