export const formatError = (error, t) => {
  if (error instanceof Error) {
    const { response = {} } = error || {}
    const { status = 401 } = response || {}

    if (status === 401) {
      return t('MSG_ERROR_LOGIN')
    }

    return t('COMMON:GENERIC_ERROR')
  }
  return JSON.stringify(error)
}