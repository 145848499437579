import React from 'react';
import moment from 'moment';
import './Footer.scss';
import { version } from './../../../package.json';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Footer() {
  const { t } = useTranslation()

  return (
    <footer>
      <div className="row">
        <div className="col-6">
          <Link to="/terms">{t('COMMON:TERMS')} - {t('COMMON:PRIVACY_POLICY')}</Link>
        </div>
        <div className="col-6 text-right">
          Copyright® Palmabit {moment().year()} - V. {version}
        </div>
      </div>
    </footer>
  );
}

export default Footer;