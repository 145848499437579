import React, { useContext, useEffect, useState } from 'react';
import AuditsPresentational from './AuditsPresentational';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../components/Loading';
import { useAlert } from 'react-alert';
import { TokenContext } from '../../components/Auth';
import { listAudits } from '../../lib/fetch/audits';
import { useQueryParam, NumberParam } from 'use-query-params';

function Audits(props) {
  const { t } = useTranslation('');
  const [token] = useContext(TokenContext);
  const [audits, setAudits] = useState();
  const [total, setTotal] = useState(0)
  const alert = useAlert();
  const { showLoading, hideLoading, loading } = useLoading();
  const [offset = 0] = useQueryParam('offset', NumberParam);
  const [limit] = useQueryParam('limit', NumberParam);

  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoading()
        const { data, headers } = await listAudits({ token, offset, limit });
        setTotal(Number(headers['x-total-count']))
        setAudits(data)
        hideLoading()
      } catch (error) {
        hideLoading()
        alert.error(t('COMMON:GENERIC_ERROR'))
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, limit]);


  return (
    <AuditsPresentational
      {...props}
      audits={audits}
      total={total}
      loading={loading}
    />
  );
}

export default Audits;
