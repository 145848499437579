import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomInput } from 'reactstrap';
const LANGS = ['af', 'sq', 'ar', 'az', 'eu', 'bn', 'be', 'bg', 'ca', 'zh', 'hr', 'cs', 'da', 'nl', 'en', 'eo', 'et', 'tl', 'fi', 'fr', 'gl', 'ka', 'de', 'el', 'gu', 'ht', 'iw', 'hi', 'hu', 'is', 'id', 'ga', 'it', 'ja', 'kn', 'ko', 'la', 'lv', 'lt', 'mk', 'ms', 'mt', 'no', 'fa', 'pl', 'pt', 'ro', 'ru', 'sr', 'sk', 'sl', 'es', 'sw', 'sv', 'ta', 'te', 'th', 'tr', 'uk', 'ur', 'vi', 'cy', 'yi']

function SelectLang({ disabled, defaultValue, innerRef }) {
  const { t } = useTranslation('SELECT_LANG');

  return (
    <CustomInput
      disabled={disabled}
      id="lang"
      defaultValue={defaultValue}
      type="select"
      name="lang"
      innerRef={innerRef}
    >
      {
        LANGS.map(lang => ({ value: lang, label: t(lang) }))
          .sort((a, b) => {
            if (a.label < b.label) return -1
            if (a.label > b.label) return 1
            return 0;
          })
          .map(({ value, label }) => (
            <option key={value} value={value}>{label}</option>
          ))
      }

    </CustomInput>
  )
}

export default SelectLang