import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import './LinkMenu.scss';
import { useTranslation } from 'react-i18next';

function LinkMenu({ match }) {
  const [t] = useTranslation('COMMON');
  const { url } = match || {}

  return (
    <div>
      <Link to={'/dashboard'} className={url === '/dashboard' ? 'active' : ''}>
        <div>{t('MENU_DASHBOARD')}</div>
      </Link>
      <Link to={'/policies'} className={url === '/policies' ? 'active' : ''}>
        <div>{t('MENU_POLICIES')}</div>
      </Link>
      <Link to={'/profile'} className={url === '/profile' ? 'active' : ''}>
        <div>{t('MENU_PROFILE')}</div>
      </Link>
      <Link to={'/app-policy'} className={url === '/app-policy' ? 'active' : ''}>
        <div>{t('MENU_APP_POLICY')}</div>
      </Link>
      <a href="https://docs.corypha.app/docs/start/" target="_blank" rel="noopener noreferrer" alt="Documentazione Corypha">
        <div>{t('MENU_DOCS')}</div>
      </a>
    </div>
  );
}

export default withRouter(LinkMenu);