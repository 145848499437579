import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { auditsDashboard } from '../../lib/fetch/audits';
import { TokenContext } from '../Auth';
import './AuditsDashboard.scss';
import { PieChart, Pie, ResponsiveContainer, Legend, Cell, Tooltip } from 'recharts'

const COLORS = [
  '#dec8fe',
  '#272343',
  '#8fc485',
  '#d87373',
  '#ed9b00',
  '#c9c9c9',
  '#f1fcfc',
  '#dddddd',
  '#777777',
  '#984f4f',
]

const PieTooltip = ({ active, payload }) => {
  if (!active) return null

  return (
    <div className="recharts-default-tooltip" style={{ margin: '0px', padding: '10px', backgroundColor: 'white', border: '1px solid #ccc', whiteSpace: 'nowrap' }}>

      <ul className="recharts-tooltip-item-list" style={{ padding: '0px', margin: '0px' }}>
        {
          payload.map((p, index) => (
            <li key={index} className="recharts-tooltip-item">
              <svg className="recharts-surface" width="14" height="14" viewBox="0 0 32 32" version="1.1" style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '4px' }}>
                <path stroke="none" fill={p.payload.fill} d="M0,4h32v24h-32z" className="recharts-legend-icon"></path>
              </svg>
              <span>{p.name}: <b>{p.value}</b></span>
            </li>
          ))
        }
      </ul>
    </div>
  )
}

const AuditsDashboard = () => {
  const [t] = useTranslation('DASHBOARD')
  const [data, setData] = useState([]);
  const [token] = useContext(TokenContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await auditsDashboard({ token, limit: 5 });
        const pieData = data.map(({ count, url, method }) => ({ value: count, name: `${method} ${url}` }))

        setData(pieData)
      } catch (error) {
        console.log(error)
      }
    }
    if (token) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <>
      {
        data && data.length > 0 &&
        <ResponsiveContainer width="100%" height={250} >
          <PieChart margin={{ bottom: 20 }}>
            <Pie
              data={data}
              dataKey="value"
              nameKey="name">
              {data.map((entry, index) => <Cell key="index" fill={COLORS[index]} />)}
            </Pie>
            <Legend />
            <Tooltip isAnimationActive={false} content={<PieTooltip />} wrapperStyle={{ zIndex: 1000 }} />
          </PieChart>
        </ResponsiveContainer>
      }
      <div className="text-center">
        <Link to="/audits">
          <Button className="btn-back">
            {t('CALLS_DETAIL')}
          </Button>
        </Link>
      </div>
    </>
  )
}

export default AuditsDashboard;