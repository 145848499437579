import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { AuthContext, ProfileContext, TokenContext } from '../../components/Auth';
import Lang from '../Lang';
import './Header.scss';

function Header({ title = '' }) {
  const [t] = useTranslation();
  const [, , removeCookie] = useCookies(['token']);
  const [, setAuthenticated] = useContext(AuthContext);
  const [profile, setProfile] = useContext(ProfileContext);
  const [, setToken] = useContext(TokenContext);

  const logout = () => {
    setAuthenticated(false);
    setToken('');
    setProfile({});
    removeCookie('token');
  }

  const { email = '', name = '' } = profile || {}

  return (
    <div className="header-crm">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <h2>{title}</h2>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8">
          <div className="cont-header-element">
            <Lang />
            <button onClick={() => logout()} className="btn-logout">Logout</button>
            <p>{t('COMMON:HELLO')}, <span>{name || email}</span></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
