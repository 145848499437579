import React from 'react';
import './Menu.scss';
import LinkMenu from '../LinkMenu';

function Menu() {

  return (
    <div className="nav-desktop">
      <nav>
        <LinkMenu />
      </nav>
    </div>
  );
}

export default Menu;
