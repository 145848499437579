import React from 'react';
import './InfoCont.scss';

function InfoCont({ message }) {
  return (
    <div className="info-cont u-center-horizontal">
      <div><i className="fas fa-info-circle u-center-center"></i></div>
      <p>{message}</p>
    </div>
  );
}

export default InfoCont;