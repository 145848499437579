import React, { Children, cloneElement, useState } from 'react';
import Proptypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import './ModalConfirm.scss';

function ModalConfirm({ children, text = '', title = '', onConfirm }) {
  const [modal, setModal] = useState(false);
  const [t] = useTranslation('COMMON');

  const toggle = () => {
    setModal(!modal);
  }

  const onClick = () => {
    toggle();
    onConfirm();
  }

  const childrenComponent = Children.map(children, child => {
    return cloneElement(child, { onClick: toggle })
  })

  return (
    <>
      {childrenComponent}
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <p>{text}</p>
        </ModalBody>
        <ModalFooter>
          <Button outline onClick={toggle}>{t('NO')}</Button>
          <Button onClick={onClick}>{t('YES')}</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

ModalConfirm.propTypes = {
  text: Proptypes.string,
  title: Proptypes.string,
  onConfirm: Proptypes.func.isRequired,
};

export default ModalConfirm;
