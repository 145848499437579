import React, { useEffect, useImperativeHandle, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import { Link } from 'react-router-dom';
import Menu from '../../components/Menu';
import BurgerMenu from '../../components/BurgerMenu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Card, Button, CardBody, Form, FormGroup, Label, Input, Badge, CustomInput, FormText } from 'reactstrap';
import Preferences from '../Preferences';
import SelectLang from '../../components/SelectLang';
import DateInput from '../../components/DateInput';
import FormErrorMessage from '../../components/FormErrorMessage';
import moment from 'moment';
import './Version.scss';
// import HashFileButton from '../../components/HashFileButton';
import FormTip from '../../components/FormTip';
import ModalConfirm from '../../components/ModalConfirm';

function VersionPresentational({ match, onSaveVersion, onSavePreference, onDeletePreference, onDeleteVersion, onPublish, version, preferences, getCurrentNumberVersion }, ref) {
  const { t } = useTranslation('VERSIONS');
  const { id, date: d, hash, lang, number, revisionedId, published, outdated, text = '' } = version || {};
  const { handleSubmit, register, errors, setValue, watch, reset } = useForm();
  const { versionId, id: policyId } = match.params;

  const date = watch('date', d || moment().format('YYYY-MM-DD'));
  const langWatch = watch('lang');
  const currentPublishedNumber = getCurrentNumberVersion(langWatch);

  useEffect(() => {
    window.scrollTo(0, 0);
    register({ name: 'date' }, { required: t('COMMON:REQUIRED') });
  }, [register, t]);

  useImperativeHandle(ref, () => ({
    resetForm() {
      const { hash = '', lang = 'it', number, date, revisionedId = false, published } = version || {};
      reset({
        hash,
        lang,
        date: date || moment().format('YYYY-MM-DD'),
        number,
        revisionedId: !!revisionedId,
        published,
      });
    }
  }));

  return (
    <div className="App">
      <Menu />
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header title={t('TITLE')} />

          <h4>
            <Link to={`/policy/${policyId}`}>
              <Button className="btn-back"><i className="fas fa-long-arrow-alt-left"></i>{t('COMMON:BTN_BACK')}</Button>
            </Link>
            {t(versionId ? 'EDIT_VERSION' : 'CREATE_VERSION')}
            {
              outdated ? <Badge pill className="badge-outdated">{t('OUTDATED')}</Badge> :
                published && <Badge pill className="badge-published">{t('PUBLISHED')}</Badge>
            }
          </h4>

          <Card>
            <CardBody>
              <Form
                onSubmit={handleSubmit(onSaveVersion)}
              >
                <div className="row">
                  <div className="col-12">
                    <FormGroup className="add-consens-cont">
                      <Label>{t('VERSION_TEXT')} *</Label>
                      <Input
                        disabled={published}
                        defaultValue={text}
                        rows={10}
                        type="textarea"
                        name="text"
                        innerRef={register({ required: t('COMMON:REQUIRED') })}
                      />
                      <FormTip tip={t('TIP_VERSION_TEXT')} />
                      <FormErrorMessage errors={errors} name="text" />
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <FormGroup className="add-consens-cont">
                      <Label>{t('HASH')} *</Label>
                      <Input
                        disabled={true}
                        defaultValue={hash}
                        type="text"
                        name="hash"
                      />
                      <FormTip tip={t('TIP_VERSION_HASH')} />
                      <FormErrorMessage errors={errors} name="hash" />
                    </FormGroup>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <FormGroup>
                      <Label>{t('LANG')} *</Label>
                      <SelectLang
                        disabled={published}
                        defaultValue={lang}
                        innerRef={register({ required: t('COMMON:REQUIRED') })}
                      />
                      <FormTip tip={t('TIP_VERSION_LANG')} />
                      <FormErrorMessage errors={errors} name="lang" />
                    </FormGroup>
                  </div>
                  <div className="col-6">
                    <FormGroup>
                      <Label>{t('NUMBER')} *</Label>
                      <Input
                        disabled={published}
                        defaultValue={number}
                        type="number"
                        name="number"
                        innerRef={register({ required: t('COMMON:REQUIRED') })}
                      />
                      <FormTip tip={t('TIP_VERSION_NUMBER')} />
                      <FormErrorMessage errors={errors} name="number" />
                    </FormGroup>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <FormGroup>
                      <Label>{t('DATE')} *</Label>
                      <DateInput
                        disabled={published}
                        name="date"
                        disablePopup={true}
                        value={date}
                        onChange={v => { setValue('date', v) }}
                      />
                      <FormTip tip={t('TIP_VERSION_DATE')} />
                      <FormErrorMessage errors={errors} name="date" />
                    </FormGroup>
                  </div>
                  {
                    currentPublishedNumber > 0 &&
                    <div className="col-6">
                      <FormGroup>
                        <CustomInput
                          defaultChecked={!!revisionedId}
                          disabled={published}
                          id="revisionedId"
                          name="revisionedId"
                          type="checkbox"
                          label={t('REVISIONED_ID')}
                          innerRef={register}
                        />
                        <FormText color="muted">
                          {t('REVISIONED_ID_MESSAGE', { number: currentPublishedNumber, lang: langWatch })}
                        </FormText>
                        <FormErrorMessage errors={errors} name="revisionedId" />
                      </FormGroup>
                    </div>
                  }
                </div>

                <div className="text-right">
                  {
                    !published &&
                    <Button type="submit" className="btn">
                      {t('COMMON:SAVE')}
                    </Button>
                  }
                </div>
              </Form>

              <hr />

              <h5>{t('COMMON:ADD_NEW_CONSENS_TITLE')}</h5>
              <Preferences
                published={published}
                preferences={preferences}
                onSubmit={(preference, e) => {
                  e.target.reset()
                  onSavePreference(preference)
                }}
                onDelete={onDeletePreference}
              />


              {
                id && !published &&
                <>
                  <hr />
                  <div className="text-right">
                    <ModalConfirm
                      title={t('TITLE')}
                      text={t('DELETE_VERSION_CONFIRM')}
                      onConfirm={onDeleteVersion}
                    >
                      <Button type="button" className="btn danger">
                        {t('COMMON:DELETE')}
                      </Button>
                    </ModalConfirm>
                    {' '}
                    {
                      !outdated &&
                      <Button type="button" className="btn" onClick={onPublish}>
                        {t('PUBLISH')}
                      </Button>
                    }
                  </div>
                </>
              }

            </CardBody>
          </Card>

        </section>
        <Footer />
      </div>

    </div>
  );
}

export default forwardRef(VersionPresentational);
