import React from 'react';
import { useTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import './Info.scss';

function Info() {
  const [t] = useTranslation();

  return (
    <div className="App">
      <Menu />

      <header className="App-header">
        {t('COMMON:INFO')}
      </header>
    </div>
  );
}

export default Info;
