import axios from 'axios';

export const updateProfile = async ({ token, id, profile }) => {
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/profiles/${id}`,
      headers: {
        Authorization: token
      },
      data: profile
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};
