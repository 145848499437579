export const getCurrentVersion = (policy = {}, lang = '') => {
  const { versions = [] } = policy || {}
  if (!Array.isArray(versions) || versions.length === 0) {
    return null
  }

  if (!lang) {
    return null
  }

  const sorted = versions.filter(v => v.published && !v.outdated && v.lang === lang).sort((v1, v2) => v2.number - v1.number)
  return sorted && sorted[0]
}