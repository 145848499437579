import axios from 'axios';

export const getReportCsv = (token, policyId) => {
  const options = {
    headers: {
      Authorization: token,
    },
    responseType: 'blob'
  }

  const query = policyId ? `?policyId=${policyId}` : ''

  return axios.get(`${process.env.REACT_APP_API_URL}/csv${query}`, options)
};