export const formatError = (error, t) => {
  const { response = {} } = error;
  const { data = {} } = response;
  const { validation = {} } = data;
  const { keys = [] } = validation;
  if (keys.includes('email')) {
    return t('COMMON:INVALID_EMAIL');
  }
  if (keys.includes('password')) {
    return t('INVALID_PASSWORD');
  }
  return t('ERROR');
}
