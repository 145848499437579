import React from 'react';
import { useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import { Button, Form, FormGroup, Label, Input, FormText, InputGroup, InputGroupAddon, CustomInput, Badge, Card, CardBody } from 'reactstrap';

function Preferences({ preferences = [], onSubmit, onDelete, published }) {
  const { t } = useTranslation('VERSIONS');
  const { handleSubmit, register, errors } = useForm();

  return (
    <div className="cont-version">
      <div className="header-version">
        <h6><span className="date" style={{ marginLeft: '0' }}>Gestione consensi</span></h6>
      </div>

      <Card>
        <CardBody style={{ padding: '15px' }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row add-consens-cont">
              {
                !published &&
                <div className="col-sm-12 col-md-6">
                  <FormGroup>
                    <Label>{t('NEW_PREFERENCES')} *</Label>
                    <InputGroup>
                      <Input
                        type="text"
                        name="name"
                        innerRef={register({ required: t('COMMON:REQUIRED') })}
                      />
                      <InputGroupAddon addonType="append">
                        <Button className="btn" type="submit">aggiungi</Button>
                      </InputGroupAddon>
                    </InputGroup>
                    {
                      errors.name && errors.name.message &&
                      <FormText>{errors.name.message}</FormText>
                    }
                    <div className="check-required">
                      <CustomInput
                        id="required"
                        type="checkbox"
                        label={t('PREFERENCE_REQUIRED')}
                        name="required"
                        innerRef={register}
                      />
                    </div>
                  </FormGroup>
                </div>
              }
              <div className="col-sm-12 col-md-6">
                <FormGroup className="list-consens">
                  <Label>{t('PREFERENCES')}</Label>
                  <ul>
                    {
                      Array.isArray(preferences) && preferences.map(({ name, required }, i) => (
                        <li key={i}>{
                          !published &&
                          <button className="btn-delete" type="button" onClick={() => onDelete(i)}>
                            <i className="far fa-trash-alt"></i>
                          </button>
                        }

                          {name} {required && <Badge pill>{t('PREFERENCE_REQUIRED')}</Badge>}
                        </li>
                      ))
                    }
                  </ul>
                </FormGroup>
              </div>
            </div>
          </Form>
        </CardBody>
      </Card>

    </div>
  );
}

export default Preferences;
