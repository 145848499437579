import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import reset from '../../lib/fetch/reset';
import { formatError } from './formatError';
import ResetPresentational from './ResetPresentational';
import { useLoading } from '../../components/Loading';

function Reset(props) {
  const [resetSuccess, setResetSuccess] = useState(false);
  const alert = useAlert();
  const { t } = useTranslation('RESET');
  const { showLoading, hideLoading } = useLoading();

  const onReset = async ({ code = '', password = '' }) => {
    showLoading()

    const { error } = await reset(code, password);

    hideLoading()

    if (error) {
      setResetSuccess(false);
      return alert.error(formatError(error, t));
    }

    setResetSuccess(true);
  }

  return (<ResetPresentational onReset={onReset} resetSuccess={resetSuccess} {...props} />);
}

export default Reset;
