import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './PrevVersionText.scss';
import { ReactComponent as ViewIcon } from '../../images/icn-view.svg';

function PrevVersionText({ text }) {
  const [t] = useTranslation('VERSIONS');
  const [isOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!isOpen)

  return (
    <Fragment>
      <div onClick={toggle} className="view-text-label">
        {t('READ_VERSION_TEXT')} <ViewIcon />
      </div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        centered={true}
        size="lg"
        zIndex="10001"
      >
        <ModalHeader toggle={toggle}>
          {t('VERSION_TEXT')}
        </ModalHeader>
        <ModalBody>
          {text}
        </ModalBody>
      </Modal>
    </Fragment>

  );
}

export default PrevVersionText;