import React, { useEffect, useState, useContext } from 'react';
import PoliciesPresentational from './PoliciesPresentational';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { listPolicies } from '../../lib/fetch/policies';
import { TokenContext } from '../../components/Auth';
import { useQueryParam, NumberParam } from 'use-query-params';
import { useLoading } from '../../components/Loading';

function Policies(props) {
  const [t] = useTranslation();
  const alert = useAlert()
  const [policies, setPolicies] = useState()
  const [total, setTotal] = useState(0)
  const [token] = useContext(TokenContext);
  const [offset = 0] = useQueryParam('offset', NumberParam);
  const [limit] = useQueryParam('limit', NumberParam);
  const { showLoading, hideLoading, loading } = useLoading();

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, limit]);

  const fetchData = async () => {
    if (!token) return

    showLoading()

    const { error, data = [], headers = {} } = await listPolicies({ token, offset, limit });

    hideLoading()

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'))
      return
    }
    setTotal(Number(headers['x-total-count']))
    setPolicies(data)
  }

  return (
    <PoliciesPresentational
      {...props}
      policies={policies}
      total={total}
      loading={loading}
    />
  );
}

export default Policies;
