import React from 'react';
import './AlertTemplate.scss';
import { types } from 'react-alert';

const alertStyle = {
  position: 'relative',
  backgroundColor: '#ffffff',
  color: '#142935',
  padding: '15px 10px 15px 25px',
  textTransform: 'uppercase',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
  width: '330px',
  fontWeight: '600',
  boxSizing: 'border-box',
  textAlign: 'center',
}

const buttonStyle = {
  marginLeft: '20px',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  color: '#142935'
}

const borderStyle = {
  position: 'absolute',
  left: '0',
  top: '0',
  width: '12px',
  height: '100%',
  borderTopLeftRadius: '10px',
  borderBottomLeftRadius: '10px',
}

const iconStyle = {
  marginRight: '20px',
}

const AlertTemplate = ({ style, options, message, close }) => {
  const { type, subtitle } = options
  return (
    <div style={{ ...alertStyle, ...style }}>
      {type === types.INFO && <div style={borderStyle} className="info"></div>}
      {type === types.SUCCESS && <div style={borderStyle} className="success"></div>}
      {type === types.ERROR && <div style={borderStyle} className="error"></div>}

      {type === types.INFO && <i style={iconStyle} className="fas fa-info-circle"></i>}
      {type === types.SUCCESS && <i style={iconStyle} className="fas fa-check-circle"></i>}
      {type === types.ERROR && <i style={iconStyle} className="fas fa-exclamation-circle"></i>}
      {
        <div>
          {message}
          <br />
          <span className="alert-subtitle">{subtitle}</span>
        </div>
      }
      <button onClick={close} style={buttonStyle}>
        <i className="fas fa-times" />
      </button>
    </div>
  )
}

export default AlertTemplate;