import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import './PaginationCustom.scss';
import { useQueryParam, NumberParam } from 'use-query-params';
import PropTypes from 'prop-types';

function PaginationCustom({ total }) {
  const [offset = 0, setOffset] = useQueryParam('offset', NumberParam);
  const [limit = 10] = useQueryParam('limit', NumberParam);
  const pages = Math.ceil(total / limit)

  return (
    <div className="cont-pagination">
      <Pagination>
        <PaginationItem disabled={parseInt(offset) === 0}>
          <PaginationLink first onClick={() => setOffset(0)} />
        </PaginationItem>
        <PaginationItem disabled={parseInt(offset) === 0}>
          <PaginationLink previous onClick={() => setOffset(offset - limit)} />
        </PaginationItem>
        {
          Array(pages).fill(null).map((n, i) => {
            const page = i + 1
            const currentPage = (offset / limit) + 1
            return (
              <PaginationItem key={page} active={page === currentPage}>
                <PaginationLink onClick={() => setOffset(i * limit)}>
                  {page}
                </PaginationLink>
              </PaginationItem>
            )
          })
        }
        <PaginationItem disabled={(offset + limit) >= total}>
          <PaginationLink next onClick={() => setOffset(offset + limit)} />
        </PaginationItem>
        <PaginationItem disabled={(offset + limit) >= total}>
          <PaginationLink last onClick={() => setOffset((pages - 1) * limit)} />
        </PaginationItem>
      </Pagination>
    </div>
  );
}

PaginationCustom.propTypes = {
  total: PropTypes.number.isRequired
}

export default PaginationCustom;