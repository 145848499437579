import React, { useContext } from 'react';
import ProfilePresentational from './ProfilePresentational';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { TokenContext, ProfileContext } from '../../components/Auth';
import { updateProfile } from '../../lib/fetch/profiles';
import { useLoading } from '../../components/Loading';

function Policy(props) {
  const [token] = useContext(TokenContext);
  const { t } = useTranslation();
  const alert = useAlert();
  const [profile, setProfile] = useContext(ProfileContext);
  const { showLoading, hideLoading } = useLoading();

  const onUpdate = async (body) => {
    const { id } = profile || {}
    if (!token || !id) return

    showLoading()

    delete body.email

    const { error, data = {} } = await updateProfile({ token, id, profile: body });

    hideLoading()

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'))
      return;
    }

    alert.success(t('COMMON:SAVE_SUCCESS'))

    setProfile(data)
  }

  return (<ProfilePresentational onUpdate={onUpdate} profile={profile} {...props} />);
}

export default Policy;
