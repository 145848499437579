import React from 'react';
import { useTranslation } from 'react-i18next';
import './Lang.scss';

function Lang() {
  const [, i18n] = useTranslation();
  const lang = i18n.language

  return (
    <div className="btn-lang">
      <button onClick={() => i18n.changeLanguage('it')} className={lang === 'it' ? 'selected' : ''}>
        ITA
      </button> |
      <button onClick={() => i18n.changeLanguage('en')} className={lang === 'en' ? 'selected' : ''}>
        ENG
      </button>
    </div>
  );
}

export default Lang;
