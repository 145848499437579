import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { AuthProvider, PrivateRoute, PublicRoute, RoutesWrapper } from './components/Auth';
import { LoadingProvider } from './components/Loading';
import AppPolicyBanner from './components/AppPolicyBanner';
import AlertTemplate from './components/AlertTemplate';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Forgot from './pages/Forgot';
import Reset from './pages/Reset';
import Version from './pages/Version';
import Verification from './pages/Verification';
import Dashboard from './pages/Dashboard';
import Info from './pages/Info';
import translations from './translations';
import './App.scss';
import PageNotFound from './pages/PageNotFound';
import { Provider as AlertProvider, positions } from 'react-alert'
import Policies from './pages/Policies';
import Policy from './pages/Policy';
import Profile from './pages/Profile';
import { QueryParamProvider } from 'use-query-params';
import AppPolicy from './pages/AppPolicy';
import Terms from './pages/Terms'
import Audits from './pages/Audits';

i18next.init({
  resources: translations,
  lng: 'it',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  return (
    <div className="App">
      <I18nextProvider i18n={i18next}>
        <AlertProvider template={AlertTemplate} position={positions.TOP_RIGHT} timeout={5000}>
          <CookiesProvider>
            <AuthProvider>
              <LoadingProvider>
                <Router>
                  <AppPolicyBanner />
                  <QueryParamProvider ReactRouterRoute={Route}>
                    <RoutesWrapper publicRedirect="/" privateRedirect="/dashboard">
                      <PublicRoute exact path="/" component={Login} />
                      <PublicRoute path="/signup" component={Signup} />
                      <PublicRoute path="/forgot" component={Forgot} />
                      <PublicRoute path="/reset" component={Reset} />
                      <PublicRoute path="/verification" component={Verification} />
                      <PrivateRoute path="/dashboard" component={Dashboard} />
                      <PrivateRoute path="/info" component={Info} />
                      <PrivateRoute exact path="/policies" component={Policies} />
                      <PrivateRoute path="/policy/:id?" component={Policy} />
                      <PrivateRoute path="/policies/:id/version/:versionId?" component={Version} />
                      <PrivateRoute path="/profile" component={Profile} />
                      <PrivateRoute path="/app-policy" component={AppPolicy} />
                      <PrivateRoute path="/audits" component={Audits} />
                      <Route path="/terms" component={Terms} />
                      <Route component={PageNotFound} />
                    </RoutesWrapper>
                  </QueryParamProvider>
                </Router>
              </LoadingProvider>
            </AuthProvider>
          </CookiesProvider>
        </AlertProvider>
      </I18nextProvider>
    </div>
  );
}

export default App;
