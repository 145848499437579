import React from 'react';
import Proptypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import { Link } from 'react-router-dom';
import Menu from '../../components/Menu';
import BurgerMenu from '../../components/BurgerMenu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import FormErrorMessage from '../../components/FormErrorMessage';
import PrevVersion from '../../components/PrevVersion';
import { Card, Button, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import './Policy.scss';
import FormTip from '../../components/FormTip';
import ModalConfirm from '../../components/ModalConfirm';

function PolicyPresentational({ match, onSavePolicy, policy, onDeletePolicy, onDeleteVersion }) {
  const { t } = useTranslation('POLICIES');
  const { code, name, versions = [] } = policy || {};
  const { handleSubmit, register, errors } = useForm();
  const { id } = match.params;

  return (
    <div className="App">
      <Menu />
      <BurgerMenu />

      <div className="corpo dashboard">
        <section>
          <Header title={t('POLICY')} />

          <h4>
            <Link to="/policies">
              <Button className="btn-back"><i className="fas fa-long-arrow-alt-left"></i>{t('COMMON:BTN_BACK')}</Button>
            </Link>
            {t(id ? 'EDIT_POLICY' : 'CREATE_POLICY')}

          </h4>

          <Card>
            <CardBody>
              <Form onSubmit={handleSubmit(onSavePolicy)}>
                <div className="row">
                  <div className="col-6">
                    <FormGroup>
                      <Label>{t('NAME')}*</Label>
                      <Input
                        defaultValue={name}
                        type="text"
                        name="name"
                        innerRef={register({ required: t('COMMON:REQUIRED') })}
                      />
                      <FormTip tip={t('TIP_POLICY_NAME')} />
                      <FormErrorMessage errors={errors} name="name"></FormErrorMessage>
                    </FormGroup>
                  </div>
                  <div className="col-6">
                    <FormGroup>
                      <Label>{t('CODE')}*</Label>
                      <Input
                        defaultValue={code}
                        type="text"
                        name="code"
                        innerRef={register({ required: t('COMMON:REQUIRED') })}
                      />
                      <FormTip tip={t('TIP_POLICY_CODE')} />
                      <FormErrorMessage errors={errors} name="code"></FormErrorMessage>
                    </FormGroup>
                  </div>
                </div>

                <div className="text-right">
                  <ModalConfirm
                    title={t('POLICY')}
                    text={t('DELETE_POLICY_CONFIRM')}
                    onConfirm={onDeletePolicy}
                  >
                    <Button type="button" className="btn danger">
                      {t('COMMON:DELETE')}
                    </Button>
                  </ModalConfirm>
                  {' '}
                  <Button type="submit" className="btn">
                    {t('COMMON:SAVE')}
                  </Button>
                </div>
              </Form>

            </CardBody>
          </Card>

          <Card>
            <CardBody>

              {
                id &&
                <h5>{t('VERSIONS:VERSIONS_LIST')}
                  <Link to={`/policies/${id}/version`}>
                    <Button className="btn btn-new-version">{t('VERSIONS:NEW_VERSION')}</Button>
                  </Link>
                </h5>
              }

              {
                versions.sort((v1, v2) => v2.number - v1.number)
                  .map((version, i) => (
                    <PrevVersion
                      open={i === 0}
                      key={version.id}
                      id={id}
                      version={version}
                      onDeleteVersion={onDeleteVersion}
                    />
                  ))
              }
            </CardBody>
          </Card>

        </section>
        <Footer />
      </div >

    </div >
  );
}

PolicyPresentational.propTypes = {
  onSavePolicy: Proptypes.func.isRequired,
};

export default PolicyPresentational;
