import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import verification from '../../lib/fetch/verification';
import { formatError } from './formatError';
import VerificationPresentational from './VerificationPresentational';
import { useLoading } from '../../components/Loading';

function Verification(props) {
  const { t } = useTranslation('VERIFICATION');
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();

  const onVerify = async ({ code = '' }) => {
    showLoading()

    const { error } = await verification(code);

    hideLoading()

    if (error) {
      return alert.error(formatError(error, t));
    }

    if (props.history) {
      props.history.push('/')
    }
  }

  return (<VerificationPresentational onVerify={onVerify} {...props} />);
}

export default Verification;
