import React from 'react';
import Proptypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Card, Button, CardHeader, CardFooter, CardBody, CardTitle,
  Form, FormGroup, Label, Input, FormText, CustomInput
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import LogoHeader from '../../components/LogoHeader';
import './Signup.scss';

function SignupPresentational({ match, onSignup, version }) {
  const { t } = useTranslation('SIGNUP');
  const { handleSubmit, register, errors } = useForm();

  return (
    <div className="App">
      <LogoHeader match={match} />

      <section>
        <div className="container">

          <Card className="u-center-horizontal login-card">
            <CardHeader>
              <CardTitle>
                {t('NO_ACCOUNT')}<br />
                <span>{t('SIGN_UP_NOW')}</span>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit(onSignup)}>
                <FormGroup>
                  <Label>{t('COMMON:EMAIL')}</Label>
                  <Input
                    type="email"
                    name="email"
                    innerRef={register({
                      required: t('COMMON:REQUIRED'),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: t('COMMON:INVALID_EMAIL')
                      }
                    })}
                  />
                  {
                    errors.email && errors.email.message &&
                    <FormText>{errors.email.message}</FormText>
                  }
                </FormGroup>

                <FormGroup>
                  <Label>{t('COMMON:PASSWORD')}</Label>
                  <Input
                    type="password"
                    name="password"
                    innerRef={register({
                      required: t('COMMON:REQUIRED'),
                      minLength: {
                        value: 6,
                        message: t('INVALID_PASSWORD')
                      },
                      maxLength: {
                        value: 20,
                        message: t('INVALID_PASSWORD')
                      }
                    })}
                  />
                  {
                    errors.password && errors.password.message &&
                    <FormText>{errors.password.message}</FormText>
                  }
                </FormGroup>

                {
                  version && version.preferences && version.preferences.map((pref) => {
                    const { id, name, required } = pref
                    const field = `preference-${id}`
                    return (
                      <FormGroup key={field}>
                        <div>
                          <CustomInput
                            type="checkbox"
                            id={field}
                            name={field}
                            label={<Link target="_blank" to="/terms">{required ? '*' : ''} {name}</Link>}
                            innerRef={register({
                              required: required && t('COMMON:REQUIRED')
                            })}
                          />
                          {
                            errors[field] && errors[field].message &&
                            <FormText>{errors[field].message}</FormText>
                          }
                        </div>
                      </FormGroup>
                    )
                  })
                }
                <Button className="u-center-horizontal">{t('COMMON:SIGNUP')}</Button>
              </Form>
            </CardBody>
            <CardFooter>
              <div className="row">
                <div className="col-6">{t('LOGIN:GOT_AN_ACCOUNT')}</div>
                <div className="col-6 text-right">
                  <Link to={'/'}>{t('LOGIN:LOGIN')} <i className="fas fa-arrow-right" /></Link>
                </div>
              </div>
            </CardFooter>
          </Card>

        </div>
      </section>
    </div>
  );
}

SignupPresentational.propTypes = {
  onSignup: Proptypes.func.isRequired,
};

export default SignupPresentational;
