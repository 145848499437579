import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Col, Badge, Collapse, Button, CardBody, Card, Form, FormGroup, Label, Input } from 'reactstrap';
import './PrevVersion.scss';
import moment from 'moment';
import FormTip from '../FormTip';
import LangBadge from '../LangBadge';
import PrevVersionText from '../PrevVersionText';
import deleteIcon from '../../images/icn-del.svg';
import ModalConfirm from '../ModalConfirm';

function PrevVersion({ id, version, open, onDeleteVersion }) {
  const [t] = useTranslation('VERSIONS');
  const [isOpen, setOpen] = useState(open);

  const { id: versionId, hash, lang, number, date, preferences = [], published, outdated, text } = version || {}
  const toggle = () => setOpen(!isOpen)

  return (
    <div className="cont-version">
      <div className="header-version">
        <h6>
          <span className="number-version">{number}</span>
          <LangBadge lang={lang} />
          <span className="date">{moment(date).format('DD-MM-YYYY')}</span>
          {
            outdated ? <Badge pill className="badge-outdated">{t('OUTDATED')}</Badge> :
              published && <Badge pill className="badge-published">{t('PUBLISHED')}</Badge>
          }
          <ModalConfirm
            title={t('TITLE')}
            text={t('DELETE_VERSION_CONFIRM')}
            onConfirm={() => onDeleteVersion(versionId)}
          >
            <Button className="btn-radius btn-delete">
              <img src={deleteIcon} alt="delete" />
            </Button>
          </ModalConfirm>
        </h6>
        <div className="btns">
          <Button onClick={toggle}><i className="fas fa-chevron-down"></i></Button>
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        <Card>
          <CardBody>
            <Form>
              <div className="row">
                <div className="col-sm-12 col-md-6 border-right">
                  <div className="cont-version-info">
                    {
                      published && text &&
                      <FormGroup row>
                        <Label md={3}>{t('VERSION_TEXT')}</Label>
                        <Col md={9}>
                          <PrevVersionText text={text} />
                        </Col>
                      </FormGroup>
                    }
                    <FormGroup row>
                      <Label md={3}>{t('HASH')}</Label>
                      <Col md={9}>
                        <Input
                          defaultValue={hash}
                          type="text"
                          name="hash"
                          disabled={true}
                        />
                        <FormTip tip={t('TIP_VERSION_HASH')} />
                      </Col>
                    </FormGroup>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="cont-version-info">
                    <FormGroup row>
                      <Label md={3}>{t('DATE')}</Label>
                      <Col md={9}>
                        <Input
                          defaultValue={date && moment(date).format('YYYY-MM-DD')}
                          type="date"
                          name="date"
                          disabled={true}
                        />
                        <FormTip tip={t('TIP_VERSION_DATE')} />
                      </Col>
                    </FormGroup>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 border-right">
                  <div className="cont-version-info ">
                    <FormGroup row>
                      <Label md={3}>{t('LANG')}</Label>
                      <Col md={9}>
                        <Input
                          defaultValue={t(`SELECT_LANG:${lang}`)}
                          type="text"
                          name="lang"
                          disabled={true}
                        />
                        <FormTip tip={t('TIP_VERSION_LANG')} />
                      </Col>
                    </FormGroup>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="cont-version-info border-right">
                    <FormGroup row>
                      <Label md={3}>{t('PREFERENCES')}</Label>
                      <Col md={9}>
                        <ul>
                          {
                            preferences.map(({ id, name, required }) => (
                              <li key={id}>{name} {required && <Badge pill>{t('PREFERENCE_REQUIRED')}</Badge>}</li>
                            ))
                          }
                        </ul>
                      </Col>
                    </FormGroup>
                  </div>
                </div>
              </div>

              {
                !published && !outdated &&
                <div className="footer-edit">
                  <Link to={`/policies/${id}/version/${versionId}`}>
                    <p>
                      <Button className="btn-edit">
                        <i className={`fas fa-pen`}></i>
                      </Button>
                      <span>
                        {t('NOT_PUBLISHED_INFO')}
                      </span>
                    </p>
                  </Link>
                </div>
              }

            </Form>
          </CardBody>
        </Card>
      </Collapse>
    </div>

  );
}

export default PrevVersion;