import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppPolicyContext } from '../../components/Auth';
import { Link, withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import './Banner.scss';

function AppPolicyBanner({ location }) {
  const [t] = useTranslation('COMMON');
  const [check] = useContext(AppPolicyContext);
  const { pathname='' } = location

  return (
    <div>
      {
        check && check.hasNewVersion && (pathname === '/dashboard' || pathname.startsWith('/policies') || pathname==='/info' || pathname.startsWith('/policy') || pathname==='/profile') &&
        <div className="banner-check-policy">
          <Link to="/app-policy">
            {t('BANNER_CHECK_POLICY')}
            <Button>Accetta ora</Button>
          </Link>
        </div>
      }
    </div>
  );
}

export default withRouter(AppPolicyBanner);