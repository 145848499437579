import React from 'react';
import { Link } from "react-router-dom";
import Proptypes from 'prop-types';
import {
  Card, Button, CardHeader, CardBody, CardTitle, CardFooter,
  Form, FormGroup, Label, Input, FormText
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import LogoHeader from '../../components/LogoHeader';
import './Forgot.scss';

function ForgotPresentational({ forgotError, forgotSuccess, match, onForgot }) {
  const { t } = useTranslation('FORGOT');
  const { handleSubmit, register, errors } = useForm();

  return (
    <div className="App">
      <LogoHeader match={match} />

      <section>
        <div className="container">

          <Card className="u-center-horizontal login-card">
            <CardHeader>
              <CardTitle>
                {t('FORGOT')}<br />
                <span>{t('RECOVER')}</span>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit(onForgot)}>
                <FormGroup>
                  <Label>{t('COMMON:EMAIL')}</Label>
                  <Input
                    type="email"
                    name="email"
                    innerRef={register({
                      required: t('COMMON:REQUIRED'),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: t('COMMON:INVALID_EMAIL')
                      }
                    })}
                  />
                  {
                    errors.email && errors.email.message &&
                    <FormText>{errors.email.message}</FormText>
                  }
                </FormGroup>

                <Button className="u-center-horizontal">{t('RESET')}</Button>
                {
                  forgotSuccess && (
                    <FormText className="result-message">
                      {t('SUCCESS_1')}
                      <br />
                      <Link to="/reset">
                        {t('SUCCESS_2')}
                      </Link>
                    </FormText>
                  )
              }
              </Form>
            </CardBody>
            <CardFooter>
              <div className="row">
                <div className="col-6">{t('LOGIN:BACK_LOGIN')}</div>
                <div className="col-6 text-right">
                  <Link to={'/'}>{t('LOGIN:LOGIN')} <i className="fas fa-arrow-right" /></Link>
                </div>
              </div>
            </CardFooter>
          </Card>

        </div>
      </section>
    </div>
  );
}

ForgotPresentational.propTypes = {
  onForgot: Proptypes.func.isRequired,
};

export default ForgotPresentational;
