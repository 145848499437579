import React from 'react';
import { Link } from 'react-router-dom';
import './LogoHeader.scss';
import Lang from '../Lang';
import logo from '../../images/logo.svg';

function LogoHeader() {
  return (
    <section className="sct-start u-bg-light">
      <header className="App-header">
        <div className="container">
          <div className="header-logo">
            <Link to="/">
              <img src={logo} className="img-fluid" alt="logo" />
            </Link>
            <div className="text-center" style={{ marginTop: '10px' }}>
              <Lang />
            </div>
          </div>
        </div>
      </header>
    </section>
  );
}

export default LogoHeader;
