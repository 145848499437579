import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { IS_MOBILE } from '../../lib/helpers/platformDetector';
import it from 'date-fns/locale/it';
import 'react-datepicker/dist/react-datepicker.css';

function DateInput({ name = '', className = '', value, onChange, disablePopup, disabled = false }) {
  const pickerRef = useRef(null);

  useEffect(() => {
    if (IS_MOBILE || disablePopup) return;
    pickerRef.current.setOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    IS_MOBILE ? (
      <Input
        disabled={disabled}
        name={name}
        className={className}
        type="date"
        onChange={ev => {
          if (!ev.target.value) return null;
          onChange((new Date(ev.target.value).toISOString()))
        }}
        value={value ? moment(value).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
      />
    ) : (
        <DatePicker
          name={name}
          disabled={disabled}
          ref={pickerRef}
          selected={value ? new Date(value) : ''}
          onChange={date => onChange(date ? date.toISOString() : '')}
          dateFormat="dd/MM/yyyy"
          locale={it}
          withPortal={!disablePopup}
        />
      )
  );
}

DateInput.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disablePopup: PropTypes.bool,
};

export default DateInput;
