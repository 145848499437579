import React, { useContext } from 'react';
import { AppPolicyContext } from '../../components/Auth';
import Menu from '../../components/Menu';
import BurgerMenu from '../../components/BurgerMenu';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import { Card, Button, CardHeader, CardBody, Form, FormGroup, CustomInput, Table } from 'reactstrap';
import PaginationCustom from '../../components/PaginationCustom';
import InfoCont from '../../components/InfoCont';
import Footer from '../../components/Footer';
import FormErrorMessage from '../../components/FormErrorMessage';
import moment from 'moment';

function AppPolicy({ onAccept, consents, total = 0, loading }) {
  const { t } = useTranslation('APP_POLICY');
  const [check] = useContext(AppPolicyContext);
  const { handleSubmit, register, errors } = useForm();
  const { version, hasNewVersion } = check || {}
  const { preferences = [], number, hash, text } = version || {}

  return (
    <div className="App">
      <Menu />
      <BurgerMenu />

      <div className="corpo">

        <section>
          <Header title={t('CONSENTS')} />

          {
            hasNewVersion &&
            <Card className="u-center-horizontal">
              <CardBody>
                <div className="info-cont u-center-horizontal text-center">
                  <div><i className="fas fa-info-circle u-center-center"></i></div>
                  <b>{t('COMMON:ATTENTION')}</b>: {t('NEW_VERSION')}<br /><br />
                  <p>{t('VERSION_NUMBER')} {number}</p>
                  <p className="text-center"><span><small>{hash}</small></span></p>
                </div>

                <p dangerouslySetInnerHTML={{ __html: text }}></p>

                <Form onSubmit={handleSubmit(onAccept)}>
                  {
                    preferences.map((pref, key) => {
                      const { id, name, required } = pref
                      const field = `preference-${id}`

                      return (
                        <FormGroup key={key}>
                          <div>
                            <CustomInput
                              type="checkbox"
                              id={field}
                              name={field}
                              label={`${required ? '*' : ''} ${name}`}
                              innerRef={register({
                                required: required && t('COMMON:REQUIRED')
                              })}
                            />
                            <FormErrorMessage errors={errors} name={field} />
                          </div>
                        </FormGroup>
                      )
                    })
                  }
                  <Button className="u-center-horizontal">{t('COMMON:SAVE')}</Button>
                </Form>
              </CardBody>
            </Card>
          }

          <Card>
            <CardHeader>
              <h4>{t('HISTORICAL_CONSENTS')}</h4>
            </CardHeader>
            <CardBody className="no-padding">
              {
                !loading && consents && consents.length === 0 && <InfoCont message={t('EMPTY_LIST')} />
              }
              {
                consents && consents.length > 0 &&
                <Table responsive hover className="data-table">
                  <thead>
                    <tr>
                      <th>{t('CONSENT')}</th>
                      <th>{t('ACCEPTED')}</th>
                      <th>{t('DATE')}</th>
                      <th>{t('POLICY_CODE')}</th>
                      <th>{t('VERSION_NUMBER')}</th>
                      <th>{t('HASH')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      consents.map((consent, key) => {
                        const { name, accepted, timestamp, version, policy } = consent
                        const { code } = policy || {}
                        const { number, hash } = version || {}
                        return (
                          <tr key={key}>
                            <td>{name}</td>
                            <td>{accepted ? t('COMMON:YES') : t('COMMON:NO')}</td>
                            <td>{moment(timestamp).format('DD-MM-YYYY')}</td>
                            <td>{code}</td>
                            <td>{number}</td>
                            <td>{hash}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>
              }

            </CardBody>
          </Card>
          {
            consents && consents.length > 0 &&
            <PaginationCustom total={total} />
          }
        </section>
      </div>
      <Footer />
    </div>

  );
}

export default AppPolicy;
