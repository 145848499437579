import axios from 'axios';

export const listAudits = async ({ token, offset = 0, limit = 50 }) => {
  const options = {
    headers: {
      Authorization: token,
    },
  }
  return axios.get(`${process.env.REACT_APP_API_URL}/audits?offset=${offset}&limit=${limit}`, options)
};

export const auditsDashboard = async ({ token, limit = 10 }) => {
  const options = {
    headers: {
      Authorization: token,
    },
  }
  return axios.get(`${process.env.REACT_APP_API_URL}/audits/dashboard?limit=${limit}`, options)
};