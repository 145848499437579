import React from 'react';
import 'flag-icon-css/css/flag-icon.min.css'

const calcLang = (lang = '') => {
  switch (lang) {
    case 'en':
    case 'EN':
      return 'gb'
    default:
      return lang.toLowerCase()
  }
}

const LangBadge = ({ lang }) => {
  if (!lang) return null
  return <span className={`flag-icon flag-icon-${calcLang(lang)}`}></span>
}

export default LangBadge;