import React from 'react';
import { FormText } from 'reactstrap';

function FormTip({ tip }) {
  if (!tip) return null

  return (
    <FormText color="muted"><i>{tip}</i></FormText>
  )
}

export default FormTip;
