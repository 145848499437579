import React, { useContext, useEffect, useState } from 'react';
import AppPolicyPresentational from './AppPolicyPresentational';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../components/Loading';
import { useAlert } from 'react-alert';
import { AppPolicyContext, ProfileContext } from '../../components/Auth';
import { acceptPolicy, fetchMyConsents } from '../../lib/fetch/appPolicy';
import { useQueryParam, NumberParam } from 'use-query-params';

function AppPolicy(props) {
  const { t } = useTranslation('');
  const [, setCheck] = useContext(AppPolicyContext);
  const [consents, setConsents] = useState();
  const [profile] = useContext(ProfileContext);
  const alert = useAlert();
  const { showLoading, hideLoading, loading } = useLoading();
  const { email: userId } = profile
  const [offset = 0] = useQueryParam('offset', NumberParam);
  const [limit] = useQueryParam('limit', NumberParam);
  const [total, setTotal] = useState(0)

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, limit, userId]);

  const fetchData = async () => {
    if (!userId) return;

    showLoading()
    const { error, data, headers } = await fetchMyConsents(userId);
    hideLoading()

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'))
      return;
    }

    setTotal(Number(headers['x-total-count']))
    setConsents(data)
  }

  const onAccept = async (values) => {
    if (!userId) return;

    const preferences = Object.keys(values).filter(key => key.startsWith('preference-')).map(key => ({
      id: Number(key.replace('preference-', '')),
      accepted: !!values[key]
    }))

    showLoading()
    const { error } = await acceptPolicy(userId, preferences);
    hideLoading()

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'))
      return;
    }

    setCheck({ hasNewVersion: false })

    alert.success(t('COMMON:SAVE_SUCCESS'))
  }

  return (
    <AppPolicyPresentational
      {...props}
      onAccept={onAccept}
      consents={consents}
      total={total}
      loading={loading}
    />
  );
}

export default AppPolicy;
