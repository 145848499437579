import axios from 'axios';

export const listPolicies = async ({ token, offset = 0, limit = 10 }) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/policies?offset=${offset}&limit=${limit}`,
      headers: {
        Authorization: token
      }
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const getPolicy = async ({ token, id }) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/policies/${id}`,
      headers: {
        Authorization: token
      }
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const savePolicy = async ({ token, id, name, code }) => {

  try {
    const config = {
      method: id ? 'put' : 'post',
      url: `${process.env.REACT_APP_API_URL}/policies` + (id ? `/${id}` : ''),
      headers: {
        Authorization: token
      },
      data: { name, code }
    }
    
    const response = await axios(config);

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const deletePolicy = async ({ token, id }) => {

  try {
    const config = {
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/policies/${id}`,
      headers: {
        Authorization: token
      },
    }
    
    const response = await axios(config);

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};