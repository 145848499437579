import React, { useContext, useEffect, useState, useRef } from 'react';
import VersionPresentational from './VersionPresentational';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { TokenContext } from '../../components/Auth';
import { getVersion, saveVersion, deleteVersion, publishVersion } from '../../lib/fetch/versions';
import { getPolicy } from '../../lib/fetch/policies';
import { savePreference, deletePreference } from '../../lib/fetch/preferences';
import { useLoading } from '../../components/Loading';
import { getCurrentVersion } from '../../lib/helpers/policy';
import clonedeep from 'lodash.clonedeep';

function Version(props) {
  const [token] = useContext(TokenContext);
  const { t } = useTranslation();
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const [version, setVersion] = useState();
  const [preferences, setPreferences] = useState([]);
  const [policy, setPolicy] = useState();
  const { id, versionId } = props.match.params
  const formRef = useRef()

  useEffect(() => {
    fetchPolicy()
      .then(p => versionId ? fetchVersion() : setNewVersion(p))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPolicy = async () => {
    if (!token || !id) return

    const { error, data = {} } = await getPolicy({ token, id });
    if (error) {
      console.log('Error get policy')
      return
    }

    setPolicy(data)
    return data
  }

  const setNewVersion = (policy) => {
    const { versions = [] } = policy || {}

    const sorted = versions.sort((v1, v2) => v2.number - v1.number)

    const maxVersion = clonedeep(sorted[0] || {});

    delete maxVersion.id
    delete maxVersion.code
    delete maxVersion.hash
    delete maxVersion.published
    delete maxVersion.revisionedId

    maxVersion.number = (maxVersion.number || 0) + 1

    const { preferences: prefs = [] } = maxVersion

    setVersion(maxVersion)
    setPreferences(prefs.map(p => { delete p.id; return p }))
    setTimeout(() => formRef.current.resetForm(), 200)
  }

  const getCurrentNumberVersion = (lang) => {
    const version = getCurrentVersion(policy, lang)
    const { number = 0 } = version || {}
    return number;
  }

  const getCurrentIdVersion = (lang) => {
    const version = getCurrentVersion(policy, lang)
    const { id = 0 } = version || {}
    return id;
  }

  const fetchVersion = async () => {
    if (!token || !id || !versionId) return

    showLoading()
    const { error, data = {} } = await getVersion({ token, id, versionId });
    hideLoading()

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'))

      const { status } = error.response || {}
      if (status === 404 || status === 400) {
        props.history.push('/ops')
      }
      return
    }

    setVersion(data)
    setPreferences(data.preferences || [])
    setTimeout(() => formRef.current.resetForm(), 200)
  }

  const onSaveVersion = async (version) => {
    version.preferences = versionId ? undefined : preferences

    const maxIdVersion = getCurrentIdVersion(version.lang)
    if (version.revisionedId && maxIdVersion) {
      version.revisionedId = maxIdVersion
    } else {
      delete version.revisionedId
    }

    showLoading()
    const { error } = await saveVersion({ token, id, version, versionId });
    hideLoading()

    if (error) {
      const { response = {} } = error
      const { data = {}, status } = response || {}
      const { message, sqlMessage } = data || {}
      if (status === 409) {
        alert.error(t('VERSIONS:ERROR_DUP_VERSION', { number: version.number, lang: version.lang }), { timeout: 10000, subtitle: sqlMessage || message })
        return
      }
      alert.error(t('COMMON:GENERIC_ERROR'), { subtitle: message })
      return;
    }

    alert.success(t('COMMON:SAVE_SUCCESS'))

    if (!versionId) {
      props.history.push(`/policy/${id}`)
    }

    fetchVersion()
  }

  const onSavePreference = async (preference) => {
    const { name, required } = preference
    const body = {
      name,
      required: !!required
    }

    if (!versionId) {
      setPreferences(preferences.concat(body))
      return
    }

    showLoading()
    const { error } = await savePreference({ token, id, versionId, preference: body });
    hideLoading()

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'))
      return;
    }

    alert.success(t('COMMON:SAVE_SUCCESS'))

    fetchVersion()
  }

  const onDeletePreference = async (index) => {
    if (!versionId) {
      setPreferences(preferences.filter((v, i) => i !== index))
      return
    }

    const preferenceId = preferences[index].id

    showLoading()
    const { error } = await deletePreference({ token, id, versionId, preferenceId });
    hideLoading()

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'))
      return;
    }

    alert.success(t('COMMON:DELETE_SUCCESS'))

    fetchVersion()
  }

  const onDeleteVersion = async () => {
    showLoading()
    const { error } = await deleteVersion({ token, id, versionId });
    hideLoading()

    if (error) {
      const { status } = error.response || {}
      alert.error(t(status === 403 ? 'VERSIONS:ERR_DELETE_PERMISSION' : 'COMMON:GENERIC_ERROR'))
      return;
    }

    alert.success(t('COMMON:DELETE_SUCCESS'))

    props.history.push(`/policy/${id}`)
  }

  const onPublish = async () => {
    showLoading()
    const { error } = await publishVersion({ token, id, versionId });
    hideLoading()

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'))
      return;
    }

    alert.success(t('COMMON:SAVE_SUCCESS'))

    props.history.push(`/policy/${id}`)
  }

  return (
    <VersionPresentational
      ref={formRef}
      onSaveVersion={onSaveVersion}
      onSavePreference={onSavePreference}
      onDeletePreference={onDeletePreference}
      onDeleteVersion={onDeleteVersion}
      onPublish={onPublish}
      preferences={preferences}
      version={version}
      getCurrentNumberVersion={getCurrentNumberVersion}
      {...props}
    />
  );
}

export default Version;
