import React from 'react';
import Proptypes from 'prop-types';
import {
  Card, Button, CardHeader, CardBody, CardTitle,
  Form, FormGroup, Label, Input, FormText
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import LogoHeader from '../../components/LogoHeader';
import './Verification.scss';

function VerificationPresentational({ match, onVerify }) {
  const { t } = useTranslation('VERIFICATION');
  const { handleSubmit, register, errors } = useForm();

  return (
    <div className="App">
      <LogoHeader match={match} />

      <section>
        <div className="container">

          <Card className="u-center-horizontal login-card">
            <CardHeader>
              <CardTitle>
                {t('VERIFICATION')}
                <br />
                <span>{t('INSERT_CODE')}</span>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit(onVerify)}>
                <FormGroup>
                  <Label>{t('CODE')}</Label>
                  <Input
                    type="text"
                    name="code"
                    autoComplete="nope"
                    innerRef={register({
                      required: t('COMMON:REQUIRED'),
                      pattern: {
                        value: /^[A-Z0-9]{4,6}$/i,
                        message: t('INVALID_CODE')
                      }
                    })}
                  />
                  {
                    errors.code && errors.code.message &&
                    <FormText>{errors.code.message}</FormText>
                  }
                </FormGroup>

                <Button className="u-center-horizontal">{t('CONFIRM')}</Button>
              </Form>
            </CardBody>
          </Card>

        </div>
      </section>
    </div>
  );
}

VerificationPresentational.propTypes = {
  onVerify: Proptypes.func.isRequired,
};

export default VerificationPresentational;
