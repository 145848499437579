import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getPolicy } from '../../lib/fetch/appPolicy';

function Terms() {
  const [, i18n] = useTranslation()
  const [text, setText] = useState();
  const lang = i18n.language

  useEffect(() => {
    const fetchPolicy = async () => {
      const { error, data } = await getPolicy(lang)
      if (error) {
        setText('error in get policy')
        return;
      }
      const { version = {} } = data || {}
      setText(version.text)
    }
    fetchPolicy()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <div style={{ margin: "3rem auto 2rem" }} className="container">
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  )
}

export default Terms
