import React, { createContext, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import createInterceptor from '../../lib/helpers/interceptor';
import fetchMe from '../../lib/fetch/me';
import { checkPolicy } from './../../lib/fetch/appPolicy';

const AuthContext = createContext([false, () => { }]);
const AppLoadedContext = createContext([false, () => { }]);
const ProfileContext = createContext([{}, () => { }]);
const TokenContext = createContext(['', () => { }]);
const AppPolicyContext = createContext([false, () => { }]);

const AuthProvider = ({ children }) => {
  const [cookies = {}, , removeCookie] = useCookies(['token']);
  const { token: authToken } = cookies
  const hasToken = !!(authToken && authToken.length > 0)

  const [isAuthenticated, setAuthenticated] = useState(hasToken);
  const [loaded, setLoaded] = useState(false);
  const [profile, setProfile] = useState({});
  const [check, setCheck] = useState({});
  const [token, setToken] = useState(authToken);

  const { email } = profile || {};

  useEffect(() => {
    createInterceptor(401, () => {
      setAuthenticated(false);
      setToken('');
      removeCookie('token');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!email) return
      const { data = {} } = await checkPolicy(email)
      setCheck(data)
    }
    fetchData()
  }, [email]);

  useEffect(() => {
    const callApi = async () => {
      const { error, data } = await fetchMe(token);

      if (error) {
        //handle error TODO
        return;
      }

      setLoaded(true);
      setProfile(data);
    }

    if (hasToken) {
      callApi();
    }

  }, [token, hasToken, setAuthenticated, setToken, removeCookie]);

  return (
    <AuthContext.Provider value={[isAuthenticated, setAuthenticated]}>
      <AppLoadedContext.Provider value={[loaded, setLoaded]}>
        <ProfileContext.Provider value={[profile, setProfile]}>
          <TokenContext.Provider value={[token, setToken]}>
            <AppPolicyContext.Provider value={[check, setCheck]}>
              {children}
            </AppPolicyContext.Provider>
          </TokenContext.Provider>
        </ProfileContext.Provider>
      </AppLoadedContext.Provider>
    </AuthContext.Provider>
  );
};

export { AuthProvider, AppLoadedContext, AuthContext, ProfileContext, TokenContext, AppPolicyContext };
