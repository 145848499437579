import React, { useEffect, useState } from 'react';
import signup from '../../lib/fetch/signup';
import { getPolicy, acceptPolicy } from '../../lib/fetch/appPolicy';
import SignupPresentational from './SignupPresentational';
import { formatError } from './formatError';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useLoading } from '../../components/Loading';

function Signup(props) {
  const [t, i18n] = useTranslation('SIGNUP');
  const alert = useAlert()
  const [version, setVersion] = useState({})
  const { showLoading, hideLoading } = useLoading()
  const lang = i18n.language

  useEffect(() => {
    const fetchPolicy = async () => {
      showLoading()
      const { error, data } = await getPolicy(lang)
      hideLoading()
      if (error) {
        console.log('error in fetch policy')
        return;
      }
      const { version } = data || {}
      setVersion(version)
    }
    fetchPolicy()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);


  const onSignup = async (values) => {
    const { email = '', password = '' } = values || {}

    showLoading()
    const { error } = await signup(email, password, i18n.language);

    if (error) {
      hideLoading()
      alert.error(formatError(error, t))
      return;
    }

    const preferences = Object.keys(values).filter(key => key.startsWith('preference-')).map(key => ({
      id: Number(key.replace('preference-', '')),
      accepted: !!values[key]
    }))

    if (preferences.length > 0) {
      await acceptPolicy(email, preferences)
    }

    hideLoading()

    if (props.history) {
      props.history.push('/verification')
    }
  }

  return (
    <SignupPresentational
      onSignup={onSignup}
      version={version}
      {...props}
    />
  );
}

export default Signup;
