import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';
import login from '../../lib/fetch/login';
import fetchMe from '../../lib/fetch/me';
import { AuthContext, ProfileContext, TokenContext } from '../../components/Auth';
import LoginPresentational from './LoginPresentational';
import { formatError } from './formatError';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../components/Loading';

function Login(props) {
  const { t } = useTranslation('LOGIN');
  const [, setCookie, removeCookie] = useCookies(['token']);
  const [, setAuthenticated] = useContext(AuthContext);
  const [, setProfile] = useContext(ProfileContext);
  const [, setToken] = useContext(TokenContext);
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();

  const onLogin = async ({ email = '', password = '' }) => {
    showLoading()
    const { error, data = {} } = await login(email, password);

    if (error) {
      hideLoading()
      alert.error(formatError(error, t))

      setAuthenticated(false);
      setToken('');
      removeCookie('token');
      return;
    }

    const { token } = data
    const { error: meError, data: profile = {} } = await fetchMe(token);

    if (meError) {
      hideLoading()
      alert.error(formatError(error, t))

      setAuthenticated(false);
      setToken('');
      removeCookie('token');
      return;
    }

    hideLoading()
    
    setAuthenticated(true);
    setProfile(profile);
    setToken(token);
    setCookie('token', token, { maxAge: process.env.REACT_APP_TOKEN_EXPIRATION });
  }

  return (<LoginPresentational onLogin={onLogin} {...props} />);
}

export default Login;
