import itCommon from './it.json';
import enCommon from './en.json';
import itDashboard from '../pages/Dashboard/translations/it';
import enDashboard from '../pages/Dashboard/translations/en';
import itSignup from '../pages/Signup/translations/it';
import enSignup from '../pages/Signup/translations/en';
import itVerification from '../pages/Verification/translations/it';
import enVerification from '../pages/Verification/translations/en';
import itLogin from '../pages/Login/translations/it';
import enLogin from '../pages/Login/translations/en';
import itForgot from '../pages/Forgot/translations/it';
import enForgot from '../pages/Forgot/translations/en';
import itReset from '../pages/Reset/translations/it';
import enReset from '../pages/Reset/translations/en';
import itPolicies from '../pages/Policies/translations/it';
import enPolicies from '../pages/Policies/translations/en';
import itVersion from '../pages/Version/translations/it';
import enVersion from '../pages/Version/translations/en';
import itProfile from '../pages/Profile/translations/it';
import enProfile from '../pages/Profile/translations/en';
import itNations from '../lib/nations/translations/it';
import enNations from '../lib/nations/translations/en';
import itSelectLang from '../components/SelectLang/translations/it';
import enSelectLang from '../components/SelectLang/translations/en';
import itAppPolicy from '../pages/AppPolicy/translations/it';
import enAppPolicy from '../pages/AppPolicy/translations/en';
import itAudits from '../pages/Audits/translations/it';
import enAudits from '../pages/Audits/translations/en';

const resources = {
  it: {
    COMMON: itCommon,
    SIGNUP: itSignup,
    VERIFICATION: itVerification,
    LOGIN: itLogin,
    FORGOT: itForgot,
    RESET: itReset,
    DASHBOARD: itDashboard,
    POLICIES: itPolicies,
    VERSIONS: itVersion,
    PROFILES: itProfile,
    NATIONS: itNations,
    SELECT_LANG: itSelectLang,
    APP_POLICY: itAppPolicy,
    AUDITS: itAudits,
  },
  en: {
    COMMON: enCommon,
    SIGNUP: enSignup,
    VERIFICATION: enVerification,
    LOGIN: enLogin,
    FORGOT: enForgot,
    RESET: enReset,
    DASHBOARD: enDashboard,
    POLICIES: enPolicies,
    VERSIONS: enVersion,
    PROFILES: enProfile,
    NATIONS: enNations,
    SELECT_LANG: enSelectLang,
    APP_POLICY: enAppPolicy,
    AUDITS: enAudits,
  },
};

export default resources;
